.custom-snackbar {
    position: fixed;
    z-index: 7;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    left: 2%;
    background-color: rgba(0, 0, 0, .8);
    /*transform: translateX(-50%);*/
}

.snackbar-bottom {
    bottom: 50px;
    animation: toBottom 0.3s;
}

@keyframes toBottom {
    from {
        opacity: 0;
        left: -50%;
    }
    to {
        opacity: 1;
        left: 2%;
    }
}

.snackbar-top {
    bottom: 50px;
    animation: toBottom 0.3s;
}

@keyframes toTop {
    from {
        bottom: -10%;
        opacity: 0
    }
    to {
        bottom: 50px;
        opacity: 1;
    }
}

