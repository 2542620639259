.draft-toolbar {
    margin-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color:black
}

.draft-editor {
    background-color: white;
    color: black;
    padding: 0px 6px;
}